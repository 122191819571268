.block {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @media print {
    display: none;
  }

  .item {
    position: relative;
    font-size: 12px;
    color: #8891A3;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:not(:last-child):hover {
      text-decoration: underline;
    }

    &:last-child {
      margin-right: 0;
      font-weight: 600;
      font-size: 14px;
      color: #363A42;

      &:after {
        display: none;
      }
    }

    .clipboard {
      height: 17px;
    }
  }

  .separator {
    margin: 0 10px;
    display: block;
    font-size: 12px;
    line-height: 15px;
    color: #8891A3;
  }
}
