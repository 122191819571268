.container {
  min-height: calc(100vh - 55px);
  flex: 1;
  display: flex;
  margin-top: 55px;

  @media print {
    display: block;
  }

  .preloader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media print {
    margin-top: 0;
  }
}
