.number {
  min-width: 32px;
  height: 32px;
  position: relative;
  background: linear-gradient(0deg, #EBEEF2, #EBEEF2);
  border-radius: 100%;
  margin-bottom: 4px;

  &[data-initial]::before {
    color: #91A0B2;
    content: attr(data-initial);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: auto;
  }
}

.content {
  background-color: #FFFFFF;
  box-shadow: 0 4px 22px rgba(43, 44, 48, 0.16);
  border-radius: 4px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  z-index: 99;

  .manager {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 11px;
    }

    .managerImg {
      position: relative;
      margin-right: 10px;

      img {
        border-radius: 100%;
      }

      .approved, .rejected, .pending {
        position: absolute;
        right: -7px;
        bottom: -6px;
        width: 17px;
        height: 17px;
      }

      .approved {
        background: url(/images/icons/i-user-approved.svg) no-repeat center/100%;
      }

      .rejected {
        background: url(/images/icons/i-user-rejected.svg) no-repeat center/100%;
      }

      .pending {
        background: url(/images/icons/i-user-pending.svg) no-repeat center/100%;
      }
    }

    a {
      padding-bottom: 4px;
      white-space: nowrap;
      color: #0072FF;
    }
  }
}
