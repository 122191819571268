.body {
  width: 467px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #363A42;
  font-weight: 600;
  margin-bottom: 12px;

  svg {
    margin-right: 12px;
  }
}

.contentWr {
  margin: 0 48px 0 39px;
  font-family: 'Inter';
  font-size: 14px;
  display: block;
}
