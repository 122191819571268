.navigation {
  margin-left: 16px;
  flex: 0 0 auto;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    width: 24px;
    height: 24px;
    z-index: 2;

    &.open {
      span {
        @media (max-width: 767px) {
          background-color: transparent;

          &:before {
            transition: all .2s;
            transform: rotate(45deg);
          }

          &:after {
            transition: all .2s;
            transform: rotate(-45deg);
          }
        }
      }
    }

    span {
      width: 18px;
      height: 2px;
      background-color: #8E9AB2;
      display: block;
      border-radius: 2px;

      &:after,
      &:before {
        transition: all .2s;
        content: ' ';
        left: 3px;
        position: absolute;
        width: 18px;
        height: 2px;
        border-radius: 2px;
        background-color: #8E9AB2;
        transform: translateY(-5px);
      }

      &:after {
        transform: translateY(5px);
      }

      @media (max-width: 767px) {
        background-color: #8E9AB2;

        &:before {
          transform: translateY(-5px);
        }

        &:after {
          transform: translateY(5px);
        }
      }
    }
  }
}
