.empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 16px;

  .emoji {
    font-size: 36px;
    margin-bottom: 21px;
  }

  .text {
    font-size: 16px;
    color: #8F939B;
    margin-bottom: 16px;
  }

  .link {
    color: #0072FF;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }
}

