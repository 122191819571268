@import '../../../pages/App/sass/colors';

@keyframes active {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image {
  display: block;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

.bodyTrigger {
  display: inline-flex;
}

.wrapper {
  min-width: 250px;
  left: 2px;
  box-shadow: 4px 4px 21px rgba(43, 44, 48, 0.19);
  border-radius: 4px;
  z-index: 1500;
  background-color: $white;
  padding: 15px;
  animation: active 0.5s ease-in-out;

  .loader {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contentTitle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;

    & > span {
      min-width: 48px;
      min-height: 48px;
    }

    .name {
      font-weight: 500;
    }

    .email, .phone {
      font-size: 12px;
      font-weight: 400;

      margin-top: 12px;
    }
  }

  .content {
    display: flex;
    gap: 10px;
    align-items: flex-end;

    .title {
      color: $regentGray;
    }

    .button {
      max-height: 24px;
      justify-content: flex-start;
      padding-left: 4px;

      &:hover {
        background-color: $zumthor;
        border: 1px solid $zumthor;
        border-radius: 4px;
      }
    }

    .blue {
      color: $blueRibbon;
    }

    .grey {
      color: $tuna;
    }
  }

  .upcoming {
    padding-top: 15px;
  }

  .rightNow {
    padding-top: 10px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 4px;
  }

  .emptyVacation {
    font-size: 12px;
    padding-top: 15px;
    color: $regentGray;
  }
}

.userInfo {
  display: flex;
  flex-direction: column;

  .icon {
    display: inline-flex;
  }
}
