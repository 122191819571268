.wrapper {
  position: relative;
  display: inline-flex;

  .statuses {
    position: absolute;
    right: -1px;
    bottom: -5px;
    min-width: 14px;
    min-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .approved, .rejected, .pending {
      position: absolute;
      z-index: auto;
      right: 1px;
      bottom: 0;
      width: 15px;
      height: 15px;
    }

    .worked {
      position: absolute;
      z-index: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .small {
      right: -0.2rem;
      font-size: 9px;
      width: 13px;
      height: 13px;
    }

    .middle {
      right: -0.3rem;
      font-size: 11px;
      width: 16px;
      height: 16px;
    }

    .big {
      right: 0;
      bottom: 1px;
      font-size: 13px;
      width: 24px;
      height: 24px;
    }

    .approved {
      background: url(/images/icons/i-accepted-new.svg) no-repeat center/100%;
    }

    .rejected {
      background: url(/images/icons/i-rejected-new.svg) no-repeat center/100%;
    }

    .pending {
      background: url(/images/icons/i-pending-new.svg) no-repeat center/100%;
    }

    .worked {
      background: url(/images/icons/i-user-status.svg) no-repeat center/100%;
    }

    .border {
      border: 2px solid #72CD04;
      border-radius: 50%;
      background: #ffffff;
    }
  }
}
