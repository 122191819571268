@import "../../../pages/App/sass/colors";

.main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 100%;
  flex: 1 1 auto;
  box-shadow: 0 0 0 1px $mystic;
  transition: padding-left .3s;

  @media (min-width: 768px) {
    padding-left: 214px;

    &.minSidebar {
      padding-left: 72px;
    }

    &.subMenu {
      padding-left: 407px;
    }

    &.minSidebar.subMenu {
      padding-left: 265px;
    }

    &.minSubnav {
      padding-left: 238px;
    }

    &.minSidebar.minSubnav {
      padding-left: 100px;
    }
  }

  .error {
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 24px 16px;
  font-size: 14px;
  position: relative;
  overflow: auto;

  @media (min-width: 768px) {
    padding: 24px;
  }

  @media print {
    padding: 0;
  }
}

.preloader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {

    > div {
      width: 16px;
      height: 16px;
    }
  }

}
