.wrap {
  display: flex;
  width: 100%;
  overflow: hidden;
  gap: 8px;

  .manager {
    position: relative;

    a > div > div {
      z-index: 0;
    }

    .approved, .rejected, .pending {
      position: absolute;
      right: -1px;
      bottom: 0;
      width: 17px;
      height: 17px;
    }

    .approvedNew, .rejectedNew, .pendingNew {
      position: absolute;
      right: -1px;
      bottom: 0;
      width: 15px;
      height: 15px;
    }

    .approved {
      background: url(/images/icons/i-user-approved.svg) no-repeat center/100%;
    }

    .rejected {
      background: url(/images/icons/i-user-rejected.svg) no-repeat center/100%;
    }

    .pending {
      background: url(/images/icons/i-user-pending.svg) no-repeat center/100%;
    }

    .approvedNew {
      background: url(/images/icons/i-accepted-new.svg) no-repeat center/100%;
    }

    .rejectedNew {
      background: url(/images/icons/i-rejected-new.svg) no-repeat center/100%;
    }

    .pendingNew {
      background: url(/images/icons/i-pending-new.svg) no-repeat center/100%;
    }
  }

  button {
    &[aria-label='showAllManagers'] {
      overflow: visible;
    }
  }
}
