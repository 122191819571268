@import "../../../pages/App/sass/colors";

.wrapper {
  position: relative;

  &.withControls>.input {
    padding: 12px 68px 12px 16px;
  }
}

.input {
  width: 100%;
  height: 40px;
  border: 1px solid $mystic;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 14px;

  &:focus {
    border-color: $blueRibbon;
  }

  &:disabled {
    background-color: $athensGray;
    border: 1px solid $mystic;
    color: $regentGray;
    pointer-events: none;
  }
}

.controls {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  display: flex;
}