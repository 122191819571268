.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  .icon {
    visibility: hidden;
    display: inline-flex;
  }

  &:hover {
    color: #8891a3;

    .icon {
      visibility: visible;
    }
  }
}
