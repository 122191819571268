.header {
  background-color: #ffffff;
  width: 100%;
  height: 55px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 3;
  border-bottom: 1px solid #E2E6ED;
  gap: 12px;

  @media print {
    display: none;
  }
}

.menuOpened {
  span {
    @media (max-width: 767px) {
      background: transparent;
      &:before {
        transition: all .2s;
        transform: rotate(45deg);
      }
      &:after {
        transition: all .2s;
        transform: rotate(-45deg);
      }
    }
  }
}

.logo {
  cursor: pointer;
  flex: 0 0 auto;

  a {
    &:hover {
      color: initial;
    }
  }

  @media (min-width: 768px) {
    margin-left: 16px;

    img {
      width: auto;
    }
  }
}

.features {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  padding-right: 16px;
}

.dropdown {
  position: absolute;
  max-height: none;
  border: 1px solid #E2E6ED;
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 22px rgba(43, 44, 48, 0.16);
  z-index: 9;

  h3 {
    font-weight: 600;
    font-style: normal;
    display: block;
    position: relative;
    font-size: 14px;
    line-height: 17px;
  }

  ul {
    display: flex;

    a {
      display: flex;
      flex-direction: column;
      color: #363A42;

      &:hover {
        color: #0072FF;
      }
    }
  }
}

.profileBtn {
  display: flex;
  cursor: pointer;

  &[data-state="open"] {
    .profileBtnName {
      &:first-child {
        color: #0072FF;

        &:after {
          background: {
            image: url("../../../../public/images/icons/dropdown-head-blue.svg");
          };
        }
      }
    }
  }

  &:hover {
    .profileBtnName {
      color: #0072FF;
    }
  }
}

.profileDropdown {
  right: 120px;
  padding: 13px 0;
  max-width: 180px;
  margin: 0;

  ul {
    flex-direction: column;

    a {
      align-items: flex-start;
      padding: 7px 16px;
    }
  }
}

.profileBtnName {
  display: none;
  user-select: none;
  color: #363A42;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  padding-right: 29px;

  &:after {
    content: '';
    position: absolute;
    right: 15px;
    width: 6px;
    height: 4px;
    background: {
      image: url("../../../../public/images/icons/dropdown-head.svg");
      position: center;
      size: contain;
      repeat: no-repeat;
    };
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
}

.profileBtnAvatar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  border-radius: 50%;

  .statusPlace {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 12px;
    width: 80px;
    height: 40px;
    border-radius: 20px;
    background-color: #E5F0FF;
    font-size: 20px;
  }
}

.profileMenuDivider {
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background-color: #e2e6ed;
}

.appsDropdown {
  right: -86px;
  width: 300px;
  padding: 5px 24px 13px 24px;

  h3 {
    padding: 28px 0 5px 0;

    &:before {
      position: absolute;
      bottom: 100%;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #F6F8FC;
    }
  }

  ul {
    align-items: center;
    flex-wrap: wrap;

    li {
      width: calc(100% / 3);
      margin: 19px 0;
    }

    a {
      align-items: center;
      font-size: 12px;

      img {
        margin-bottom: 6px;
      }
    }
  }
}

.appsBtn {
  color: #8FA0B4;
  margin-right: 16px;
  border-radius: 4px;
  transition: .3s ease;

  &.open {
    color: #007AFF;
  }

  &:hover {
    background-color: #F2F8FF;
  }

  &:active {
    color: #FFFFFF;
    background-color: #007AFF;
  }
}

.logout {
  padding: 7px 16px;
  justify-content: flex-start;

  &:hover {
    color: #0072FF;
    text-decoration: underline;
  }
}

.disabled {
  button, input {
    pointer-events: none;
  }
}

@media (min-width: 576px) {
  .profileDropdown {
    right: 0;
  }

  .appsBtn {
    margin-left: 24px;
  }

  .appsBtn, .notificationsBtn {
    margin-right: 32px;
  }
}

@media print {
  .header {
    display: none;
  }
}
