.helpContainer {
  @media print {
    display: none;
  }
}

.title {
  font-size: 20px;
  color: #363A42;
  margin-bottom: 24px;
  font-weight: 600;
  padding-right: 24px;
}

.addNewRef {
  margin-left: -0.725rem;
}

.item {
  display: flex;
  justify-content: space-between;
  width: 99%;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  .itemTitle {
    font-size: 14px;
    font-weight: 400;
  }
}

.noReference {
  font-size: 16px;
  color: #8891A3;
}

.help {
  display: flex;
  cursor: pointer;
  color: #8FA0B4;
  transition: .3s;
  border-radius: 4px;

  &.open {
    color: #0072FF;
  }

  &:hover {
    background-color: #F2F8FF;
  }

  &:active {
    background-color: #0072FF;
    color: white;
  }
}

.emptyArticle {
  a {
    color: #007AFF;

    &:hover {
      text-decoration: underline;
    }
  }
}

.loader {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.referenceContainer {
  position: fixed;
  top: 60px;
  right: 32px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(43, 44, 48, 0.158326), 0 8px 24px rgba(43, 44, 48, 0.236943);
  border-radius: 4px;
  min-height: 10vh;
  width: 560px;
  padding: 24px;
  display: none;
  z-index: 2;

  &.active {
    display: flex;
    flex-direction: column;
  }

  &.mobile {
    width: 95%;
    right: 10px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #EBEEF2;
}

.closeModal {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  content: "";
}

.articleWr {
  position: fixed;
  top: 60px;
  right: 32px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(43, 44, 48, 0.158326), 0 8px 24px rgba(43, 44, 48, 0.236943);
  border-radius: 4px;
  min-height: 10vh;
  width: 560px;
  padding: 24px;
  display: none;
  max-height: 80vh;
  z-index: 2;

  &.active {
    display: flex;
    flex-direction: column;
  }

  .title {
    display: flex;

    .articleBack {
      margin-right: 10px;
    }
  }

  .articleContent {
    overflow-y: scroll;
    display: block;

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    ol {
      list-style: decimal;
    }

    ul {
      list-style: circle;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
    }

    mark {
      background: rgb(253, 234, 155);
      color: rgb(17, 19, 25);
    }

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 4px;
      height: 60px;
    }

    &::-webkit-scrollbar-thumb {
      background: #8891A3;
      border-radius: 4px;
    }
  }

  &.mobile {
    width: 95%;
    right: 10px;
  }
}

.goToKnowlege {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-top: 16px;
  color: #0072FF;
  font-size: 13px;
  font-weight: 600;

  .bottomButtons {
    display: flex;
    flex-direction: row;

    button:first-child {
      margin-right: 8px;
    }
  }
}
