@import "../../../pages/App/sass/colors";

.label {
  display: flex;
  align-items: center;
  flex: 1 0 120px;
  max-width: 215px;
  color: $lynch;
  font-weight: bold;
  font-size: 14px;

  &.baseline {
    align-self: baseline;
    line-height: 40px;
  }

  &.black {
    color: $tuna;
  }

  &.fullWidth {
    flex: 1;
  }

  .imgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  .required {
    margin-left: 4px;
    color: $redOrange;
  }
}
