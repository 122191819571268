.body {
  width: 560px;
}

.linkInput {
  margin-top: 8px;
  color: #0072FF;
}

.label {
  flex: unset;
}
