.wrap {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(97,111,138,0.7);
  padding: 20px 0;

  &.warning, &.stop, &.success {
    .body {
      width: 520px;
      padding-left: 44px;

      .title {
        margin-bottom: 16px;
        padding-right: 34px;
        font-size: 24px;
      }

      &:before {
        position: absolute;
        content: "";
        top: 29px;
        left: 24px;
        width: 24px;
        height: 22px;
        background: {
          image: url("/images/icons/i-warning.svg");
          repeat: no-repeat;
        };
      }
    }
  }

  &.stop {
    .body {
      &:before {
        background: {
          image: url("/images/icons/i-stop-red.svg");
          repeat: no-repeat;
        };
      }
    }
  }

  &.success {
    .body {
      &:before {
        background: {
          image: url("/images/icons/i-success.svg");
          repeat: no-repeat;
        };
      }
    }
  }
}

.body {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px 0;
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(43, 44, 48, 0.158326), 0 8px 24px rgba(43, 44, 48, 0.236943);
  border-radius: 4px;

  .title {
    font-weight: bold;
    font-size: 20px;
    color: #2B2C30;
    padding: 0 46px 0 24px;
    margin-bottom: 24px;
  }

  .note {
    position: relative;
    color: #8891A3;
    padding: 0 15px 0 24px;
    font-size: 12px;
    line-height: 15px;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background: {
        image: url("/images/icons/i-info.svg");
        repeat: no-repeat;
        position: center;
        size: cover;
      };
    }
  }

  .content {
    height: 100%;
    padding: 0 24px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      padding-right: 20px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #E5F0FF;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: #E5F0FF;
    }
  }

  .bottom {
    padding: 0 24px;
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .buttons {
      display: flex;
      gap: 16px;
    }

    button {
      font-weight: bold;
    }
  }

  .padding {
    padding: 24px 24px 0;
  }

  .error {
    flex: 1;
    padding-left: 34px;
    padding-right: 45px;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 24px;
      height: 24px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: url("/images/icons/alert-error.svg") no-repeat center;
    }

    p {
      color: #B44747;
      word-break: break-word;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 2;
    content: "";
  }
}
