.list {
  display: flex;
  flex-direction: column;
  max-width: 780px;
  overflow: hidden;
  padding-top: 16px;
  flex: 1;
  height: 100%;

  .notifications {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 16px;
    padding-right: 4px;

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #E2E6ED;
      border-radius: 4px;
    }

    .separator {
      width: 100%;
      height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background-color: #EBEEF2;
      font-size: 13px;
      font-weight: 600;
      border-radius: 4px;
      margin-top: 12px;
      margin-bottom: 14px;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    padding: 0;
    background: white;
    border-top: 1px solid #EBEEF2;
    z-index: 1;
    margin-bottom: -6px;
    padding-top: 10px;

    button {
      color: #0072FF;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline; /* Підкреслення при наведенні */
      }
    }
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 15px;

    > div {
      width: 14px;
      height: 14px;
    }
  }

  .loaderScroll {
    display: flex;
    justify-content: center;

    > div {
      width: 10px;
      height: 10px;
    }
  }
}

.emptyList {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
