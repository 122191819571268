.btn {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;
  padding: .5rem 0.875rem;
  min-height: 2.25rem;
  width: max-content;
  gap: 8px;

  &.fullWidth {
    width: 100%;
  }

  &.minWidth {
    min-width: 82px;
  }

  &.sm {
    line-height: 1rem;
    font-size: 1rem;
    min-height: 2rem;
    padding: .5rem .725rem;
  }

  &.lg {
    min-height: 2.5rem;
    padding: 0.875rem 1.15rem;
  }

  &.trashed {
    color: #8891A3;
  }

  &.adminApprove {
    background-color: #36B37E;

    &:hover {
      background-color: #19925E;
    }
  }

  &.adminReject {
    background-color: #B44747;

    &:hover {
      background-color: #963232;
    }
  }

  .loader {
    inset: 0;
    border-radius: 4px;
    position: absolute;
    background-color: #0056C2;
    justify-content: center;
    align-items: center;
  }

  &:disabled {
    cursor: not-allowed;
    color: #8891A3 !important;
    background-color: #EBEEF2 !important;
    border-color: transparent !important;
  }

  &.ghost {
    color: initial;
    background-color: transparent;

    &:hover {
      background-color: #e5f0ff;
    }

    &.link {
      color: #0072ff;
      background-color: transparent;
      width: 100%;
      justify-content: flex-start;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.contain {
    &.primary {
      color: #FFFFFF;
      background-color: #0072ff;

      &:hover {
        background-color: #2988ff;
      }

      &:active {
        background-color: #0056C2;
      }

      .loader {
        background-color: #0056C2;
      }
    }

    &.secondary {
      background-color: #FFA90B;

      &:hover {
        background-color: #FFBD45;
      }
    }

    &.error {
      color: #FFFFFF;
      background-color: #B44747;

      &:hover {
        background-color: #963232;
      }

      .loader {
        background-color: #963232;
      }
    }

    &.grey {
      background-color: #ebeef2;
      color: #363a42;
    }

    &.blue {
      color: #0072FF;

      &:hover {
        background-color: #e5f0ff;
      }
    }

    &.green {
      background-color: #36B37E;
      color: #FFFFFF;

      &:hover {
        background-color: #19925E;
      }
    }

    &.red {
      background-color: #B44747;
      color: #FFFFFF;

      &:hover {
        background-color: #963232;
      }
    }
  }

  &.outline {
    border: 1px solid transparent;

    &.default {
      background-color: #fff;
      border-color: #ebeef2;
      color: #0072ff;

      &:hover {
        background-color: #e5f0ff;
        border: 1px solid #e5f0ff;
      }

      .loader {
        inset: -1px;
        background-color: #0072FF;
      }
    }

    &.green {
      border-color: #249F5D;
      color: #249F5D;
      background-color: #FFFFFF;

      &:hover {
        background-color: #249F5D20;
        border-color: #249F5D20;
      }
    }

      &.grey {
        color: #8E9AB2;
        background-color: #FFFFFF;
        border: 1px solid #8E9AB2;

        &:hover {
          background-color: #8E9AB220;
        }
      }

    &.error {
      color: #B44747;
      background-color: #FFFFFF;
      border: 1px solid #B44747;

      &:hover {
        background-color: #FAC3C3;
      }

      .loader {
        background-color: #B44747;
      }
    }
  }

  &.outlineGray {
    border: 1px solid transparent;

    &.error {
      color: #B44747;
      background-color: #FFFFFF;
      border: 1px solid #ebeef2;

      &:hover {
        background-color: #FAC3C3;
      }

      .loader {
        background-color: #B44747;
      }
    }
  }
}
