.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    height: 55px;
    border-bottom: 1px solid #e2e6ed;
    display: flex;
    align-items: center;
    padding: 0 24px;
    gap: 24px;

    .features {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;

      .small {
        height: 24px;
        width: 24px;
        background-color: #e2e6ed;
        border-radius: 4px;
      }

      .long {
        height: 24px;
        width: 140px;
        background-color: #e2e6ed;
        border-radius: 4px;
      }

      .avatar {
        height: 40px;
        width: 40px;
        background-color: #e2e6ed;
        border-radius: 100px;
      }

      .user {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .loaderWrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      display: flex;
      justify-content: center;

      > div {
        width: 16px;
        height: 16px;
      }
    }
  }
}
