.body {
  width: 560px;
  height: 95vh;
  margin-left: auto;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 18px;

  > div:first-child {
    margin-bottom: 18px;

    p {
      color: #363A42;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;

    .header {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 2;

      .separator {
        width: 100%;
        height: 1px;
        background-color: #EBEEF2;
      }
    }
  }
}
