.item {
  box-shadow: 0 4px 8px rgba(43, 44, 48, 0.1);
  border-radius: 4px;
  line-height: 18px;
  padding: 12px;
  max-width: 410px;

  &.error {
    background-color: #FFF2F2;
    border: 1px solid rgba(180, 71, 71, 0.19);
    color: #B44747;
  }

  &.success {
    background-color: #F2FEF1;
    border: 1px solid #B8E8B7;
    color: #36B37E;
  }

  .contentWrapper {
    display: flex;
    gap: 12px;

    img {
      align-self: flex-start;
    }
  }

  a {
    color: #0072FF;
    white-space: nowrap;
  }
}
