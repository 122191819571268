@import "../../../pages/App/sass/colors";

.sidebar {
  display: flex;
  background-color: #F8FBFF;
  box-shadow: 1px 0 0 0 #e7edf9;
  flex-shrink: 1;
  position: fixed;
  top: 55px;
  z-index: 1;
  bottom: 0;

  @media print {
    display: none;
  }

  .mainNav {
    display: flex;
    flex-direction: column;
    transition: .3s;
    width: 214px;
  }

  .resizeBtn {
    display: flex;
    margin-bottom: 12px;
    padding-left: 14px;

    span {
      transition: .3s;

      &:last-of-type {
        color: $regentGray;
        white-space: nowrap;
      }
    }

    .toggleSidebarButton {
      display: flex;
      align-items: center;

      .toggleIcon {
        position: relative;
        width: 30px;
        height: 30px;
        background-color: $zumthor;
        border-radius: 5px;
        margin-right: 21px;

        &:before {
          position: absolute;
          transition: .3s;
          content: '';
          left: 0;
          width: 30px;
          height: 30px;
          background: url(/images/icons/i-sort.svg) no-repeat center;
        }

        &:not(.open) {
          margin-right: 12px;

          &:before {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }

  .versions {
    padding: 15px 16px 0;
    margin: 15px 0 18px;
    transition: .3s;
    color: $regentGray;
    font-size: 12px;
    line-height: 15px;

    p {
      word-break: break-word;

      &:nth-child(2) {
        margin-top: 10px;
      }
    }

    .copyright {
      display: block;
      margin-top: 16px;
    }
  }

  &.minimized {
    .versions {
      padding: 0 12px 0 14px;
    }

    .resizeBtn {
      span {
        &:last-of-type {
          color: rgba(0, 0, 0, 0);
        }
      }
    }

    .mainNav {
      width: 72px;
    }

    .nav {
      position: relative;

      &Text {
        width: 55px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
      }

      .navLink {
        padding: 6px 0 6px;
        font-size: 10px;
        color: $regentGray;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .icon {
          position: absolute;
          top: 6px;
          left: 24px;

          @supports (-moz-appearance:none) {
            left: 20px;
          }
        }

        &.active {
          color: $blueRibbon;
        }

        &:before {
          top: 8px;
          transform: none;
        }

        &.review {
          &:after {
            position: absolute;
            top: 6px;
            left: 44px;
            content: '';
            width: 6px;
            height: 6px;
            background-color: $blueRibbon;
            border-radius: 100%;
          }
        }
      }

      .navLinkCount {
        position: absolute;
        top: 6px;
        right: 16px;
        width: 6px;
        height: 6px;
        padding: 0;
        border-radius: 100%;

        @supports (-moz-appearance:none) {
          right: 6px;
        }

        & > span {
          width: 0;
          height: 0;
          visibility: hidden;
        }
      }
    }
  }

  .nav {
    padding: 0;
    overflow-x: hidden;
    flex: 1;

    @supports (-moz-appearance:none) {
      scrollbar-color: #E5F0FF transparent;
      scrollbar-width: thin;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #E5F0FF;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: #E5F0FF;
    }
  }

  .navItem {
    position: relative;
    padding-left: 0;


    &:not(:first-of-type) {
      margin-top: 8px;
    }

    &:hover {
      background-color: $zumthor;
    }
  }

  &.mobile {
    left: 0;
  }
}

.navLink {
  position: relative;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-height: 52px;
  font-weight: 600;
  font-size: 16px;
  color: $tuna;

  .icon {
    display: inline-flex;
    transition: .3s ease;
  }

  &:not(.active) {
    .icon {
      color: #8891A3;
    }
  }

  &:hover {
    color: inherit;
    background-color: $zumthor;
  }

  .navLinkCount {
    transition: .3s ease;
    margin-left: 2px;
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    color: $white;
    padding: 3px 7px;
    height: 20px;
    background-color: $blueRibbon;
    border-radius: 10px;
    font-weight: 400;
    font-style: normal;

    & > span {
      display: inline-block;
    }
  }

  &.active {
    background-color: $zumthor;
    box-shadow: inset 3px 0 0 0 $blueRibbon;
    color: $blueRibbon;
  }
}

@media (max-width: 767px) {
  .sidebar {
    position: fixed;
    top: 54px;
    left: -100%;
    height: 100%;
    margin: 0;
    padding-top: 8px;
    transition: left .3s ease;
    z-index: 2;

    .mainNav {
      padding-bottom: 54px;
    }
  }
}

@media (min-width: 768px) {
  .sidebar {
    .mainNav {
      padding-top: 14px;
    }
  }
}

.wrapper {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 14px 4px 14px 14px;
}


.sidebar.minimized .wrapper {
  padding: unset;
}


.badgeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 100%;
  padding: 14px 7px;
}

.sidebar.minimized .badgeButton {
  padding: unset;

  & > p {
    color: transparent;
  }
}
