.wrapper {
  flex: 1;
  max-width: 564px;

  .trigger {
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #E2E6ED;
    background: #F8FBFF;
    color: #8891A3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &Label {
      flex: 1;
      text-align: start;
    }

    .shortcuts {
      display: inline-flex;
      gap: 4px;

      &Item {
        background: #e5f0ff;
        color: #8E9AB2;
        min-height: 16px;
        min-width: 16px;
        border-radius: 4px;
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        &.shortcutsItemMac {
          font-size: 14px;
        }
      }
    }
  }
}

.content {
  width: auto;
  background-color: #FFF;
  box-shadow: 0 4px 22px rgba(43, 44, 48, 0.16);
  border-radius: 4px;
  z-index: 10;
}

.searchWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 49px;
  padding: 12px 16px;
  border-bottom: 1px solid #0072FF;

  .searchIcon {
    display: inline-flex;
    color: #8891A3;
  }

  [cmdk-input] {
    width: 100%;
  }
}

.tabsList {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;

  .tabsTrigger {
    padding: 16px;
    border-bottom: 1px solid #F2F8FF;

    &Label {
      font-weight: 600;
    }

    &Badge {
      font-size: 12px;
      margin-left: 5px;
      border-radius: 10px;
      background-color: #F2F8FF;
      padding: 2px 8px;
    }

    &[data-state="active"] {
      border-bottom-color: #0072FF;
      border-bottom-width: 2px;

      .label {
        color: #0072FF;
      }
    }
  }
}

.list {
  padding: 12px 16px;
  max-height: 400px;
  overflow: auto;

  &Item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 0;
    border-bottom: 1px solid #EBEEF2;

    &Icon,
    &Where {
      display: inline-flex;
      color: #8891A3;
    }

    &Where {
      font-weight: bold;
      flex: 1;
      justify-content: flex-end;
    }

    a {
      color: #0072FF;

      &.inactive {
        color: #8891A3;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .link {
      width: 36%;
    }

    &:hover {
      background-color: #F2F8FF;
    }
  }
}
