@import '../../../pages/App/sass/colors';

.item {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  max-height: 24px;

  div {
    font-size: 14px;
    font-weight: 600;

    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
