@import '../../../pages/App/sass/colors';

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  position: relative;
  height: 32px;
  min-width: 32px;
  transition: background-color .5s, color .5s;
  border-radius: 4px;

  img {
    width: 31px;
    height: 31px;
    padding: 0 8px;
    border-radius: 4px;
    color: #0072FF;
    background-color: #E5F0FF;
    background-position: center;

    &:hover {
      background-color: #EBEEF2;
      transition: background-color .5s, color .5s;
    }
  }

  &.outline {
    border: 1px solid #EBEEF2;
    background-color: #FFFFFF;
    color: #8891A3;

    &:hover,
    &[data-state="open"] {
      background-color: #E5F0FF;
      color: #0072FF;
    }

    &:not(.ghost) {
      &:hover,
      &[data-state="open"] {
        border: 1px solid #E5F0FF;
      }
    }
  }

  &.ghost {
    border: none;
    color: #8891A3;
    background-color: #FFFFFF;

    &:hover {
      background-color: #E5F0FF;
      color: #0072FF;
    }
  }

  &.secondary {
    color: #FFFFFF;
    background-color: transparent;

    &:hover {
      background-color: rgb(49, 61, 82);
    }
  }

  &.active {
    background: $aliceBlue;
    border-radius: 3px;
    color: $blueRibbon;
  }

  &.sm {
    height: 24px;
    min-width: 24px;
  }
}
