@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.content {
  transform-origin: var(--radix-hover-card-content-transform-origin);
  animation: scaleIn 0.2s ease;
}

.root {
  width: 365px;
  padding: 16px 16px 19px;
  background-color: #ffffff;
  border-radius: 4px;
  filter: drop-shadow(0px 8px 24px rgba(43, 44, 48, 0.24)) drop-shadow(0px 4px 8px rgba(43, 44, 48, 0.16));
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.imageBox {
  min-width: 48px;
  min-height: 48px;
}

.headInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  word-break: break-all;
}

.headInfo .name,
.headInfo .email {
  color: #363A42;
}

.name {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  word-break: break-all;

  &:hover {
    opacity: 0.5;
  }
}

.email {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;

  &:hover {
    opacity: 0.5;
  }
}

.divider {
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background-color: #EBEEF2;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 14px;
  font-size: 12px;
  word-break: break-all;

  .item {
    display: flex;
    gap: 12px;

    > svg {
      min-width: 20px;
      min-height: 20px;
    }

    > p {
      padding-top: 3px;
    }
  }
}

.slack {
  color: #0072FF;
}
