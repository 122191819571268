$black: #000000;
$parisM: #0C0466;
$blueRibbon: #0072FF;
$azureRadiance: #007AFF;
$lynchBlue: #C8D0DF;
$zumthor: #E5F0FF;
$aliceBlue: #F2F8FF;
$scrollGray:  #F1F4F8;
$scrollBlue: #CFD4DE;
$lightWhite: #FFEBEB;
$tableGray: #91A0B2;
$lynch: #616f8a;
$lynchGray: #EFEFEF;
$regentGray: #8891A3;
$departmentGray: #F3F3F3;
$vacationGray: #F7F7F8;
$lineGray: #EBF3FF;
$lightGray: #B7C1D4;
$iron: rgb(43 44 48 / 16%);
$gallery: rgba(174, 174, 174, .1);
$mystic: #E2E6ED;
$mysticGray: #575757;
$athensGray: #EBEEF2;
$white: #FFFFFF;
$oceanGreen: #36B37E;
$darkGreen: #15A034;
$approvedGreen: #D5FCDE;
$tuna: #363A42;
$redOrange: #FF2E2E;
$chestnut: #B44747;
$lightRed: #FFF2F2;
$brown: #967510;
$desert: #FFF9C3;

// TIMESHEET
$reported: #000;
$billed: #36b37e;
$approved: #0072ff;
