@font-face {
  font-family: 'Twemoji Country Flags';
  unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067, U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
  src: url('https://cdn.jsdelivr.net/npm/country-flag-emoji-polyfill@0.1/dist/TwemojiCountryFlags.woff2') format('woff2');
}

html,
body {
  padding: 0;
  margin: 0;
  color: #363A42;
  font-size: 14px;
  font-family: -apple-system, Inter, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.emoji {
  font-family: -apple-system, "Twemoji Country Flags", Inter, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.marked-yellow {
  background: yellow;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

address, body, code, div, form, h1, h2, h3, header, html,
i, img, label, li, object, p, span, summary, time, tr, ul,
button, input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

button {
  font-size: 1rem;
  cursor: pointer;
}

ul, ol {
  list-style: none;
}

.flex {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.justifyEnd {
  justify-content: flex-end;
}

.subNav {
  display: block;
  border-left: 1px solid #e7edf9;
  left: 214px;
  transition: left .3s ease;

  @media (max-width: 767px) {
    left: -300px;

    &.minimized:not(.open) {
      left: -300px;
    }
  }

  &.open {
    left: 215px;
    transition: left .3s .2s ease;
  }

  &.minimized {
    left: 72px;
  }
}

.containerInfo {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h1 {
    margin-bottom: 0;
  }

  .formWrapper {
    max-width: 490px;
  }
}

.modalW600 {
  width: 600px;
}

.trashed {
  color: #8891A3;
}

.createBtn {
  position: absolute;
  right: 24px;
  top: 16px;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.DropdownMenuContent {
  display: flex;
  flex-direction: column;
  min-width: 140px;
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 10;
}
.DropdownMenuContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem {
  font-size: 14px;
  line-height: 1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  position: relative;
  user-select: none;
  outline: none;
  cursor: pointer;
}

.DropdownMenuItem[data-disabled] {
  color: hsl(255deg 4% 79%);
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted] {
  background-color: #F2F8FF;
}

.DropdownMenuArrow {
  fill: white;
}

.tiptap {
  table {
    td, th {
      position: relative;
    }

    .column-resize-handle {
      position: absolute;
      top: 0;
      right: -2px;
      bottom: -2px;
      width: 4px;
      background-color: #e5f0ff;
      pointer-events: none;
    }
  }
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.AccordionTrigger {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 0;
  font-size: 12px;
  font-family: inherit;
  font-weight: 700;
  color: #0072FF;
  background-color: transparent;
}

.AccordionContent {
  overflow: hidden;
}

.AccordionContent[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionChevron {
  color: #0072FF;
  transform: rotate(180deg);
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionTrigger[data-state='open'] > .AccordionChevron {
  transform: rotate(0deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
