.canScrollLeft {
  z-index: 999;
  position: absolute;
  width: 5%;
  height: 50px;
  top: 50px;
  left: 0;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.canScrollRight {
  z-index: 999;
  position: absolute;
  width: 5%;
  height: 50px;
  top: 50px;
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.chips {
  position: relative;
  display: flex;
  margin-top: 16px;
  padding-bottom: 6px;
  overflow: hidden;

  .chip {
    position: relative;
    text-align: center;
    padding-right: 8px;
    display: flex;
    justify-content: flex-start;
    color: #EBEEF2;

    button {
      width: 100%;
      border-radius: 8px;
      background-color: #EBEEF2;
      font-weight: 600;
      gap: 5px;
      color: #363A42;
      font-size: 14px;
      border: 1px solid transparent;

      &:hover {
        background-color: #E5F0FF;
        border: 1px solid #007AFF;
      }
    }

    &.active {
      button {
        pointer-events: none;
        color: #007AFF;
        border: 1px solid #007AFF;
        background-color: #E5F0FF;
      }
    }

    .count {
      transition: .3s ease;
      margin-left: 2px;
      display: inline-block;
      font-size: 12px;
      line-height: 15px;
      color: #fff;
      padding: 3px 7px;
      height: 20px;
      background-color: #0072ff;
      border-radius: 10px;
      font-weight: 400;
      font-style: normal;
    }
  }
}
