.listItem {
  position: relative;
  min-height: 32px;
  display: flex;
  flex-direction: column;
  padding: 16px 0 4px 0;
  border-bottom: 1px solid #EBEEF2;
  list-style: none;
  transition: box-shadow 0.3s ease;
  color: #363A42;
  font-size: 13px;

  &:hover {
    box-shadow: 4px 4px 4px 0 #2B2C3014;
    background-color: transparent;

    .link path {
      stroke: #0072FF;
    }
  }

  &::before {
    content: none;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 600;
    margin-right: 32px;
    color: #363A42;
    padding-left: 12px;
    cursor: initial;

    .link {
      position: absolute;
      right: 10px;
      top: 10px;

      &:hover {
        background-color: #0072FF;
        transform: scale(2);
        padding: 3px;
        border-radius: 2px;
      }

      &:hover path {
        stroke: white;
      }
    }

    .title {
      margin-bottom: 12px;

      span {
        display: inline-block;
        text-align: left;
      }
    }

    .message {
      font-weight: 400;
      margin-bottom: 12px;

      span {
        display: inline-block;
        text-align: left;
      }
    }

    .date {
      white-space: nowrap;
      font-size: 13px;
      color: #8891A3;
      padding-bottom: 8px;
      font-weight: 400;
    }
  }

  .status {
    margin-top: 8px;
  }
}

.noRead {
  padding-left: 10px;

  .description {
    cursor: pointer;
    padding-left: 18px;
  }

  &:hover {
    background-color: #E5F0FF;
  }

  &::before {
    content: '';
    position: absolute;
    top: 18px;
    width: 10px;
    height: 10px;
    background-color: #0072FF;
    border-radius: 50%;
  }
}
