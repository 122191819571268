.wrapper {
  display: flex;

  &.white {
    > div {
      background-color: #fff;
    }
  }

  &.blue {
    > div {
      background-color: #0072FF;
    }
  }

  &.green {
    > div {
      background-color: #34ac54;
    }
  }

  > div {
    width: 8px;
    height: 8px;

    border-radius: 100%;
    margin: 2px;
    display: inline-block;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    &:nth-child(1) {
      animation: scale .75s -.24s infinite cubic-bezier(.2,.68,.18,1.08);
    }

    &:nth-child(2) {
      animation: scale .75s -.12s infinite cubic-bezier(.2,.68,.18,1.08);
    }

    &:nth-child(3) {
      animation: scale .75s 0s infinite cubic-bezier(.2,.68,.18,1.08);
    }
  }
}

@keyframes scale {
  30% {
    -webkit-transform: scale(.3);
    transform: scale(.3);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
