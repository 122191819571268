.tabs {
  position: relative;
  display: flex;

  .tab {
    position: relative;
    text-align: center;
    padding: 8px 10px;
    display: flex;
    justify-content: flex-start;

    button {
      width: 100%;
      font-weight: 600;
      color: #363A42;
      font-size: 16px;
    }

    &.active {
      button {
        pointer-events: none;
        color: #007AFF;
      }

      &:before {
        position: absolute;
        content: "";
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #007AFF;
      }
    }
  }
}
