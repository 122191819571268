@import '../../../pages/App/sass/colors';

.wrapper {
  display: inline-flex;
  align-items: center;

  .trashed {
    color: $regentGray;
  }

  .avatarWrapper {
    display: inline-flex;
    min-width: 24px;
    margin-right: 8px;
  }
}
